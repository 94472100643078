import {
    AppBar,
    createTheme,
    CssBaseline,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { DrawerComponent } from '../drawer/Drawer';
import { getSortedAppRoutes } from '../routing/Routing';

const theme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgrounColor: '#b4b1a7',
                },
            },
        },
    },
});
const useStyles = makeStyles({
    navLinks: {
        // marginLeft: theme.spacing(5),
        display: 'flex',
        margin: 'auto',
    },
    logo: {
        flexGrow: '1',
        cursor: 'pointer',
        textAlign: 'left',
        margin: 'auto!important',
    },
    link: {
        textDecoration: 'none',
        color: 'white',
        fontSize: '20px',
        fontWeight: 'bold',
        // marginRight: theme.spacing(10),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        margin: 'auto',
        '&:hover': {
            color: '#dcdcdc',
            //   borderBottom: "1px solid white",
        },
    },
});

export function Navbar() {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AppBar position="static">
            <CssBaseline />
            <Typography variant="h4" className={classes.logo}>
                <Link to="/" key="logoHomeLink">
                    <img
                        height="70px"
                        src="/images/logos/strands_logo.svg"
                        alt="Strands Logo"
                    />
                </Link>
            </Typography>
            <Toolbar>
                {/* <Avatar variant="square" src="/images/brand.png" /> */}
                {isMobile ? (
                    <DrawerComponent />
                ) : (
                    <div className={classes.navLinks}>
                        {getSortedAppRoutes().map((r) => (
                            <Link
                                to={r.path}
                                className={classes.link}
                                key={r.name}
                            >
                                {r.name}
                            </Link>
                        ))}
                    </div>
                )}
            </Toolbar>
        </AppBar>
    );
}
