import { createTheme, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { headerFont, imageCss } from '../../utils/styles';

const theme = createTheme();
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        maxWidth: '850px',
        margin: 'auto',
    },
    headerPaper: {
        paddingBottom: theme.spacing(2),
        fontFamily: headerFont,
        fontSize: '3.5rem!important',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(2),
    },
    cocoQuoteImage: {
        width: '100%',
        maxWidth: '768px',
        marginBottom: theme.spacing(2),
        ...imageCss,
    },
    image: {
        width: '100%',
    },
    carousel: {
        margin: 'auto',
    },
    carouselDiv: {
        maxWidth: '400px',
        margin: 'auto',
    },
});

export function Home() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Helmet>
                <title>Strands Salon of Sonora, Ca | Home</title>
                <meta
                    name="description"
                    content="We opened Strands in June of 2021 in Sonora, CA. It is the result of many
                    hopes, dreams and lots of experience! As licensed professionals,
                    we are dedicated to our craft and truly enjoy what we do. We are
                    committed to service and value and it is reflected in the
                    loyalty of our customers."
                />
                <meta
                    name="keywords"
                    content="Salon, Hair, Wax, Sonora, Licensed, Professionals"
                />
            </Helmet>
            <Paper className={classes.paper}>
                <Typography variant="h5" className={classes.headerPaper}>
                    Commitment to our clients
                </Typography>
                We opened Strands in June of 2021. It is the result of many
                hopes, dreams and lots of experience! As licensed professionals,
                we are dedicated to our craft and truly enjoy what we do. We are
                committed to service and value and it is reflected in the
                loyalty of our customers.
            </Paper>
            <img
                src="/images/home/home_coco-chanel.jpg"
                className={classes.cocoQuoteImage}
                alt="Beauty begins the moment you decide to be yourself -Coco Chanel"
            />

            <div className={classes.carouselDiv}>
                <Carousel
                    className={classes.carousel}
                    useKeyboardArrows={true}
                    infiniteLoop={true}
                    interval={5000}
                    autoPlay={true}
                    showStatus={false}
                >
                    <div>
                        <img
                            alt="salon interior"
                            src="/images/home/407A1564.jpg"
                        />
                    </div>
                    <div>
                        <img
                            alt="salon interior"
                            src="/images/home/407A1625.jpg"
                        />
                    </div>
                    <div>
                        <img
                            alt="salon interior"
                            src="/images/home/407A1617.jpg"
                        />
                    </div>
                    <div>
                        <img
                            alt="salon interior"
                            src="/images/home/407A1604.jpg"
                        />
                    </div>
                    <div>
                        <img
                            alt="salon interior"
                            src="/images/home/407A1607.jpg"
                        />
                    </div>
                </Carousel>
            </div>
        </div>
    );
}
