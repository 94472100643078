import {
    Card,
    CardContent,
    CardMedia,
    Container,
    Divider,
    Grid,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet';
import BioModal from '../../components/modals/bio-modal/bio-modal';
import { getSubdomain } from '../../components/routing/Routing';
import { headerFont, imageCss } from '../../utils/styles';

const tempList = [
    {
        name: 'Kelli Slater',
        bio: "Welcome to Strands!  I have been a licensed cosmetologist for 29 years.  I'm passionate about people and our profession which is why I chose to open my own salon in 2021.  It also gave me the opportunity to work alongside my daughter!  This industry is always changing and there is nothing better in the world than helping people feel and look their best!  I specialize in color and haircutting, but also offer a full menu of hair services.  I also teach cosmetology at our local school, Sonora High Cosmetology in Sonora.  I have been doing that in addition to working in a salon since 2002.  To say I love our business may be an understatement!  Along my journey, I have attended continuing education on a regular basis every year.  This is the foundation of staying relevant, not only as an educator, but as a stylist.  I have been married for 28 years to my husband, Mark and we have two children Allison and Logan.  In my spare time I love to be outdoors!  I love camping, hiking, riding my bike, golfing (not good at it, it's just fun!) swimming and gardening. I love the mountains and the beach, being with my friends and family and doing new things!  My goal here at Strands is that you can come to a warm, friendly environment and enjoy receiving your services from one of our talented team of stylists, without pretense.  Maybe if you are extra lucky you will get to have one of Grandma's favorite, homemade cookies when you visit! Instagram @kelcosmo",
        subDomain: 'kelli',
        image: '/images/team/kelli.jpg',
    },
    {
        name: 'Dawn Harris',
        bio: "I have been a cosmetologist/manicurist for 39 years.  I was born and raised here in Sonora.  I previously owned my own salon for 25 years, and I still love my profession!  I occasionally substitute teach at the local cosmetology school. I have attended hundreds of continuing education classes over the years, including several specialty Redken classes. I also enjoy doing hair extensions.  When I'm not working, I love to be outdoors, camping, fishing, kayaking and hanging out with my family and grandkids.",
        subDomain: 'dawn',
        image: '/images/team/dawn.jpg',
    },
    {
        name: 'Whitney Ohler',
        bio: "I've been doing hair since I was 6 years old really, but professionally since 2015!  I love doing hair and expressing my creative side.  I specialize in hair color, haircutting, eyebrow waxing and I'm also certified in LED teeth whitening.  On my days off, I'm raising 3 beautiful, crazy boys with my wonderful husband.  I love being a boy mom, it keeps me busy camping, riding, and being adventurous.  Despite my busy lifestyle, I still make time to attend continuing education classes that are necessary for our ever changing profession!  For some samples of my work checkout my Facebook and Instagram pages @hairbywhitney.o",
        subDomain: 'whitney',
        image: '/images/team/whitney.jpg',
    },
    {
        name: 'Allison Slater',
        bio: "Hello there!  I'm a second generation hair stylist and have the pleasure of working alongside my mom, and the rest of my talented salon family! I have been born and raised in Tuolumne County and feel blessed to call this place home.  I worked my way through school waitressing and lifeguarding and I am blessed to now have a career I love, where I can make my own schedule!  I am eager to continue building my clientele!  I love doing hair and NAILS (you should check out my nail art) pedicures and body waxing, including bikini and Brazilian.  I am also offering eyelash extensions!  I'm a hard working dog mama, who in my off time I love to go camping or do any outdoor activities, with my boyfriend, family and friends. Hope to see you soon at Strands!  Instagram @hair_by_al21",
        subDomain: 'allison',
        image: '/images/team/allison.jpg',
    },
    {
        name: 'Jodi Sardella-Ditler',
        bio: "I am a 4th generation Tuolumne County resident. I have been a licensed cosmetologist for 38 years, and previously owned my own salon for 15 years.  I'm a certified Redken color specialist and I have attended hundreds of advanced education classes during my career. I love doing colors, perms, haircuts, waxing and ear piercing.  I have been married for 34 years and I have 2 sons and 2 grandsons. When I'm not working, I love spending time with my grandsons and spending time at the beach or in the mountains with my family.",
        subDomain: 'jodi',
        image: '/images/team/jodi.jpg',
    },
    {
        name: 'Anissa Schlichtmann',
        bio: "Hi!  I'm a licensed cosmetologist and massage therapist.  I have 19years experience, specializing in therapeutic massage.  I have a variety of methods that I use so that you leave feeling better than when you walked in!  I also incorporate Himalayan Hot Stone, suction cups, scraping tools and essential oils into my massages.  I also offer a full array of hair services and face, body, and Brazilian waxing.  I can get you ready for summer, vacation, or just everyday! I graduated from California Beauty College in Modesto.  I love doing hair and the feeling that my clients get when they leave feeling happy and even better about themselves!  I have been married to my husband Justin for 13 years.  We have 2 children, Kenadi (11) and Deacon (9).  They keep this mama busy in my time off with baseball and dance!  I love working here, as we are like our own family and it's a fun working environment!",
        subDomain: 'anissa',
        image: '/images/team/anissa.jpg',
    },
    {
        name: 'Mackenzie Paz',
        bio: "Hi Y'all!  I'm a small town girl who has a passion for all things beauty!  I specialize in 'lived in' blonding and color techniques to ensure a soft and more natural grow out, by creating a seamless blend.  I also specialize in bridal hair and make-up.  There is no better feeling than making a bride's dream glam come to life on her big day! I also offer pedicures, eyelash extensions and waxing.  When I'm not 'behind the chair', I enjoy camping, hiking, and exploring new places with my husband, fur baby, and family.  We love a good adventure!  September of 2022 we will be welcoming a sweet, baby girl to the world!  I'm over the moon excited to take on the new role as a mama! (Like I said, we love a good adventure 😉) Check out my Instagram @styledbykenzie",
        subDomain: 'mackenzei',
        image: '/images/team/mackenzei.jpg',
    },
];

const styles = makeStyles(() => ({
    teamImg: {
        margin: 'auto',
        maxWidth: '768px',
        width: '100%',
        ...imageCss,
    },
    teamImgText: {
        fontFamily: headerFont,
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    nameInCard: {
        margin: 'auto!important',
    },
    nameInFont: {
        fontFamily: headerFont,
        fontSize: '3.0rem!important',
        margin: 'auto!important',
    },
    bioInCard: {
        margin: 'auto!important',
    },
}));

export function Team() {
    const classes = styles();
    const subDomain = getSubdomain();
    const peopleToShow =
        subDomain === 'www' || subDomain === 'dev'
            ? tempList
            : tempList.filter((n) => n.subDomain === subDomain);

    return (
        <div>
            <Helmet>
                <title>Strands Salon of Sonora, Ca | Team</title>
                <meta
                    name="description"
                    content="Learn about our team of licensed professionals!"
                />
                <meta
                    name="keywords"
                    content="Salon, Hair, Wax, Sonora, Licensed, Professionals"
                />
            </Helmet>
            <img
                className={classes.teamImg}
                src="/images/team/our_team.jpg"
                alt="The Strands Team"
            />
            <Container>
                <Divider variant="middle" />
                <br />
                <Grid container spacing={2}>
                    {peopleToShow.map((n) => (
                        <Grid item xs={12} md={4} key={n.name}>
                            <Card sx={{ maxWidth: 400 }}>
                                <CardMedia
                                    component="img"
                                    alt={n.name}
                                    height="300"
                                    image={n.image}
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            className={classes.nameInCard}
                                        >
                                            <Typography
                                                className={classes.nameInFont}
                                                gutterBottom
                                                variant="h5"
                                                component="div"
                                            >
                                                {n.name}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            className={classes.bioInCard}
                                        >
                                            <BioModal
                                                buttonLabel="Bio"
                                                header={n.name}
                                                body={n.bio}
                                            ></BioModal>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
}
