import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import {
    Avatar,
    Container,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
} from '@mui/material';
import React, { FC } from 'react';
import {
    SalonService,
    SalonServices,
    ServicesEnum,
} from '../../utils/salon-services.service';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { Helmet } from 'react-helmet';
import { sendEvent } from '../../components/routing/analytics';
import { imageCss } from '../../utils/styles';

const styles = makeStyles(() => ({
    specialistModal: {
        minWidth: '350px',
    },
    closeButtonStyle: {
        float: 'right',
    },
    img: {
        margin: 'auto',
        width: '100%',
        maxWidth: '270px',
        ...imageCss,
    },
}));

interface ServicesProps {}

const Services: FC<ServicesProps> = () => {
    const classes = styles();
    const salonServices = new SalonServices();

    const [open, setOpen] = React.useState(false);
    const [selectedService, setSelectedService] =
        React.useState<SalonService>();

    const handleOpen = (serviceId: ServicesEnum) => {
        setOpen(true);
        setSelectedService(salonServices.getSalonService(serviceId));
        sendEvent('service_view', `service viewed: ${ServicesEnum[serviceId]}`);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Helmet>
                <title>Strands Salon of Sonora, Ca | Services</title>
                <meta
                    name="description"
                    content="Scroll through the services that we offer and click on a 
                    service to see which of our team members are available for that service!"
                />
                <meta
                    name="keywords"
                    content="Salon, Hair, Wax, Sonora, Products, Hair Products, Services, Professional, Team Members"
                />
            </Helmet>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={12}>
                                <img
                                    className={classes.img}
                                    src="/images/services/407A1659.jpg"
                                    alt="Haircut in chair"
                                />
                            </Grid>
                            <Grid item xs={6} md={12}>
                                <img
                                    className={classes.img}
                                    src="/images/services/407A1650.jpg"
                                    alt="Nail Service being performed"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Paper>
                            Click on a service to see which one of our
                            specialist can assist you.
                            <List>
                                {salonServices
                                    .getSalonServices()
                                    .map((d, i, data) => (
                                        <ListItem
                                            disablePadding
                                            key={d.id}
                                            onClick={() => handleOpen(d.id)}
                                        >
                                            <ListItemButton>
                                                <ListItemText
                                                    primary={
                                                        String.fromCharCode(
                                                            8226
                                                        ) +
                                                        ' ' +
                                                        d.name
                                                    }
                                                />
                                                <ListItemIcon>
                                                    <LaunchIcon
                                                        sx={{ fontSize: 17 }}
                                                    />
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={12}>
                                <img
                                    className={classes.img}
                                    src="/images/services/407A1638.jpg"
                                    alt="Haircut in chair"
                                />
                            </Grid>
                            <Grid item xs={6} md={12}>
                                <img
                                    className={classes.img}
                                    src="/images/services/407A1631.jpg"
                                    alt="Hair color in chair"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={'xs'}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    {selectedService?.name}
                </DialogTitle>
                <DialogContent dividers={true}>
                    {/* <div className={classes.specialistModal}> */}
                    {salonServices
                        .getSpecialists(selectedService?.id)
                        .map((s) => (
                            <ListItem alignItems="flex-start" key={s.name}>
                                <ListItemAvatar>
                                    <Avatar alt={s.name} src={s.headShot} />
                                </ListItemAvatar>
                                <ListItemText primary={s.name} />
                            </ListItem>
                        ))}
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.closeButtonStyle}
                        variant="outlined"
                        onClick={handleClose}
                        startIcon={<CloseIcon />}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Services;
