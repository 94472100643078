import { makeStyles } from '@mui/styles';
import { Route, Routes } from 'react-router-dom';
import { Contact } from '../../features/contact/Contact';
import { Home } from '../../features/home/Home';
import Products from '../../features/Products/Products';
import Services from '../../features/Services/Services';
import { Team } from '../../features/team/Team';
import { Navbar } from '../navbar/Navbar';
import { usePageTracking } from './analytics';

export const appRoutes = [
    {
        path: '/',
        routePath: '*',
        name: 'Home',
        element: <Home />,
        navOrder: 0,
    },
    {
        path: '/team',
        routePath: '/team',
        name: 'Team',
        element: <Team />,
        navOrder: 2,
    },
    {
        path: '/services',
        routePath: '/services',
        name: 'Services',
        element: <Services />,
        navOrder: 3,
    },
    {
        path: '/products',
        routePath: '/products',
        name: 'Products',
        element: <Products />,
        navOrder: 4,
    },
    {
        path: '/contact',
        routePath: '/contact',
        name: 'Contact Us',
        element: <Contact />,
        navOrder: 5,
    },
];

export function getSortedAppRoutes() {
    const tempRoutes = appRoutes.sort((a, b) => a.navOrder - b.navOrder);

    return tempRoutes;
}

export function getSubdomain() {
    return window.location.host.split('.')[1]
        ? window.location.host.split('.')[0]
        : 'www';
}

const useStyles = makeStyles({
    mainBody: {
        margin: '1em',
    },
});

export function Routing() {
    usePageTracking();
    const classes = useStyles();
    return (
        <div>
            <Navbar />
            <div className={classes.mainBody}>
                <Routes>
                    {appRoutes.map((r) => (
                        <Route
                            path={r.routePath}
                            element={r.element}
                            key={r.routePath}
                        />
                    ))}
                </Routes>
            </div>
        </div>
    );
}
