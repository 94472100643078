import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { headerFont } from '../../../utils/styles';
import { sendEvent } from '../../routing/analytics';

const buttonStyle = {};

const closeButtonStyle = {
    float: 'right',
};

const headerStyle = {
    fontFamily: headerFont,
    fontSize: '3.5rem!important',
};

type BioModalProps = {
    buttonLabel: string;
    header: string;
    body: string;
};

export default function BioModal({ ...props }: BioModalProps) {
    const { buttonLabel, header, body } = props;

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
        sendEvent('user_interaction', `bio view: ${header}`);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Button
                sx={buttonStyle}
                variant="outlined"
                onClick={handleOpen}
                startIcon={<DescriptionIcon />}
            >
                {buttonLabel}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    <Typography
                        sx={headerStyle}
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        {header}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {body}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={closeButtonStyle}
                        variant="outlined"
                        onClick={handleClose}
                        startIcon={<CloseIcon />}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
