import { Container, createTheme, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { imageCss } from '../../utils/styles';

interface ProductsProps {}

const theme = createTheme();
const useStyles = makeStyles({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(2),
        margin: 'auto',
    },
    defaultImg: {
        background: 'white',
        margin: 'auto',
        maxWidth: '768px',
        width: '100%',
        ...imageCss,
    },
    logoImg: {
        background: 'white',
        margin: 'auto',
        padding: '1.5rem',
        maxWidth: '768px',
        width: '100%',
        ...imageCss,
    },
    logoImgBlkBackground: {
        background: 'black',
        margin: 'auto',
        padding: '1.5rem',
        maxWidth: '768px',
        width: '100%',
        ...imageCss,
    },
});

const Products: FC<ProductsProps> = () => {
    const classes = useStyles();

    return (
        <Container>
            <Helmet>
                <title>Strands Salon of Sonora, Ca | Products</title>
                <meta
                    name="description"
                    content="When you walk in, hopefully you will feel like you are
                    meeting an old friend. We strive to provide a warm,
                    welcoming atmosphere where you can relax and enjoy the
                    services you are here for."
                />
                <meta
                    name="keywords"
                    content="Salon, Hair, Wax, Sonora, Products, Hair Products"
                />
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Paper className={classes.paper}>
                        When you walk in, hopefully you will feel like you are
                        meeting an old friend. We strive to provide a warm,
                        welcoming atmosphere where you can relax and enjoy the
                        services you are here for.
                        <br />
                        <br />
                        <img
                            src="/images/products/407A1568.jpg"
                            alt="Strands interior of salon"
                            className={classes.defaultImg}
                        />
                    </Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <img
                                src="/images/products/redken-vector-logo.svg"
                                alt="Redken product logo"
                                className={classes.logoImg}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <img
                                src="/images/products/wet-brush-vector-logo.svg"
                                alt="Wet Brush product logo"
                                className={classes.logoImg}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <img
                                src="/images/products/olaplex-vector-logo.svg"
                                alt="Olaplex product logo"
                                className={classes.logoImg}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <img
                                src="/images/products/matrix-logo-white.png"
                                alt="Matrix product logo"
                                className={classes.logoImgBlkBackground}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <img
                                src="/images/products/malibu-c.svg"
                                alt="Malibu C product logo"
                                className={classes.logoImg}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <img
                                src="/images/products/Biolage-2022-Logo-Main-Black.png"
                                alt="Biolage product logo"
                                className={classes.logoImg}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <img
                                src="/images/products/K18_Logo_RGB.png"
                                alt="K18 product logo"
                                className={classes.logoImg}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <img
                                src="/images/products/myra_bag_logo.jpg"
                                alt="Myra bag product logo"
                                className={classes.logoImg}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Products;
