import MenuIcon from '@mui/icons-material/Menu';
import {
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { getSortedAppRoutes } from '../routing/Routing';

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: 'none',
        color: 'black',
        fontSize: '20px',
    },
    icon: {
        color: 'white',
    },
}));

export function DrawerComponent() {
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);

    return (
        <div>
            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <List>
                    {getSortedAppRoutes().map((r, i, routes) => (
                        <div key={r.name}>
                            <ListItem
                                onClick={() => setOpenDrawer(false)}
                                key={r.name}
                            >
                                <ListItemText>
                                    <Link to={r.path} className={classes.link}>
                                        {r.name}
                                    </Link>
                                </ListItemText>
                            </ListItem>
                            {i + 1 === routes.length ? null : <Divider />}
                        </div>
                    ))}
                </List>
            </Drawer>
            <IconButton
                onClick={() => setOpenDrawer(!openDrawer)}
                className={classes.icon}
            >
                <MenuIcon />
            </IconButton>
        </div>
    );
}
