export enum ServicesEnum {
    'styling' = 1,
    'hairCutting',
    'color',
    'highLighting',
    'balayage',
    'perming',
    'hairExtensions',
    'pedicures',
    'nailService',
    'faceWaxing',
    'bodyWaxing',
    'teethWhitening',
    'message',
    'makeup',
    'weddings',
}

export interface SalonService {
    id: ServicesEnum;
    name: string;
    all?: boolean;
}

export interface Employee {
    name: string;
    headShot: string;
    services: ServicesEnum[];
}

export class SalonServices {
    private employees: Employee[] = [
        {
            name: 'Kelli Slater',
            headShot: '/images/services/kelli.jpg',
            services: [ServicesEnum.balayage, ServicesEnum.faceWaxing],
        },
        {
            name: 'Allison Slater',
            headShot: '/images/services/allison.jpg',
            services: [
                ServicesEnum.perming,
                ServicesEnum.pedicures,
                ServicesEnum.nailService,
                ServicesEnum.faceWaxing,
                ServicesEnum.bodyWaxing,
                ServicesEnum.weddings,
            ],
        },
        {
            name: 'Dawn Harris',
            headShot: '/images/services/dawn.jpg',
            services: [
                ServicesEnum.balayage,
                ServicesEnum.perming,
                ServicesEnum.hairExtensions,
                ServicesEnum.nailService,
            ],
        },
        {
            name: 'Whitney Ohler',
            headShot: '/images/services/whitney.jpg',
            services: [
                ServicesEnum.balayage,
                ServicesEnum.faceWaxing,
                ServicesEnum.teethWhitening,
            ],
        },
        {
            name: 'Jodi Sardella-Ditler',
            headShot: '/images/services/jodi.jpg',
            services: [ServicesEnum.perming, ServicesEnum.faceWaxing],
        },
        {
            name: 'Anissa Schlichtmann',
            headShot: '/images/services/anissa.jpg',
            services: [
                ServicesEnum.faceWaxing,
                ServicesEnum.message,
                ServicesEnum.bodyWaxing,
            ],
        },
        {
            name: 'Mackenzie Paz',
            headShot: '/images/services/mackenzei.jpg',
            services: [
                ServicesEnum.balayage,
                ServicesEnum.perming,
                ServicesEnum.pedicures,
                ServicesEnum.makeup,
                ServicesEnum.weddings,
                ServicesEnum.faceWaxing,
            ],
        },
    ];

    private services: SalonService[] = [
        {
            id: ServicesEnum.styling,
            name: 'Styling',
            all: true,
        },
        {
            id: ServicesEnum.hairCutting,
            name: 'Hair Cutting',
            all: true,
        },
        {
            id: ServicesEnum.color,
            name: 'Color',
            all: true,
        },
        {
            id: ServicesEnum.highLighting,
            name: 'Highlighting',
            all: true,
        },
        {
            id: ServicesEnum.balayage,
            name: 'Balayage',
        },
        {
            id: ServicesEnum.perming,
            name: 'Perming',
        },
        {
            id: ServicesEnum.hairExtensions,
            name: 'Hair Extensions',
        },
        {
            id: ServicesEnum.pedicures,
            name: 'Pedicures',
        },
        {
            id: ServicesEnum.nailService,
            name: 'Nail Service',
        },
        {
            id: ServicesEnum.faceWaxing,
            name: 'Face Waxing',
        },
        {
            id: ServicesEnum.bodyWaxing,
            name: 'Body Waxing',
        },
        {
            id: ServicesEnum.teethWhitening,
            name: 'Teeth Whitening',
        },
        {
            id: ServicesEnum.message,
            name: 'Message',
        },
        {
            id: ServicesEnum.makeup,
            name: 'Makeup',
        },
        {
            id: ServicesEnum.weddings,
            name: 'Weddings',
        },
    ];

    public getEmployees(): Employee[] {
        return this.employees;
    }

    public getSalonServices(): SalonService[] {
        return this.services;
    }

    public getSalonService(serviceId: ServicesEnum): SalonService | undefined {
        return this.services.find((s) => s.id === serviceId);
    }

    public getSpecialists(serviceId: ServicesEnum | undefined): Employee[] {
        if (serviceId) {
            var service = this.services.find((s) => s.id === serviceId);
            return this.employees
                .filter(
                    (e) => service?.all || e.services.indexOf(serviceId) > -1
                )
                .sort((a, b) => {
                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
        }

        return [];
    }
}
