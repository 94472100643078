import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/system';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { Routing } from './components/routing/Routing';
import './fonts/salty_feathers/stylesheet.css';

const theme = createTheme();
const useStyles = makeStyles(() => ({
    app: {
        textAlign: 'center',
        backgroundImage: 'url("/images/backgrounds/shiplap_background_4.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        paddingBottom: theme.spacing(2),
    },
}));

function App() {
    const classes = useStyles();

    return (
        <div className={classes.app}>
            <Router>
                <Routing />
            </Router>
        </div>
    );
}

export default App;
