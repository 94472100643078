import {
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import makeStyles from '@mui/styles/makeStyles';
import { Helmet } from 'react-helmet';
import { imageCss } from '../../utils/styles';

interface Hours {
    day: string;
    hours: string;
}

const useStyles = makeStyles({
    socialMediaImg: {
        margin: '0.5rem',
        width: '3rem',
    },
    shopImage: {
        width: '100%',
        maxWidth: '768px',
        ...imageCss,
    },
    shopImageDiv: {
        padding: '1rem',
    },
});

export function Contact() {
    const classes = useStyles();

    const hours: Hours[] = [
        {
            day: 'Sunday',
            hours: 'Closed',
        },
        {
            day: 'Monday',
            hours: 'Closed',
        },
        {
            day: 'Tuesday',
            hours: '9AM-5PM',
        },
        {
            day: 'Wednesday',
            hours: '9AM-5PM',
        },
        {
            day: 'Thursday',
            hours: '9AM-5PM',
        },
        {
            day: 'Friday',
            hours: '9AM-5PM',
        },
        {
            day: 'Saturday',
            hours: 'Closed',
        },
    ];

    return (
        <Container>
            <Helmet>
                <title>Strands Salon of Sonora, Ca | Contact Us</title>
                <meta
                    name="description"
                    content="Stop by our Salon, we would love to meet you and answer any
                    questions that you might have!"
                />
                <meta
                    name="keywords"
                    content="Salon, Hair, Wax, Sonora, Products, Hair Products, Location"
                />
            </Helmet>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            sx={{ mt: 4, mb: 2 }}
                            variant="h6"
                            component="div"
                        >
                            Strands Salon
                        </Typography>
                        <a
                            href="https://www.google.com/maps/place/Strands+Salon/@37.978214,-120.324998,16z/data=!4m5!3m4!1s0x0:0x3849e3ccd0a0e797!8m2!3d37.9782139!4d-120.3249984?hl=en"
                            target="_blank"
                            rel="noreferrer"
                        >
                            14570 Suite J <br />
                            Sonora, CA 95370 <br />
                        </a>
                        <strong>
                            Phone:{' '}
                            <a href="tel:209-536-4380" rel="noreferrer">
                                209-536-4380
                            </a>
                        </strong>
                        <br />

                        <Typography
                            sx={{ mt: 4, mb: 2 }}
                            variant="h6"
                            component="div"
                        >
                            Salon hours *
                        </Typography>

                        <Table
                            sx={{
                                minWidth: 300,
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: 'none',
                                },
                            }}
                            size="small"
                            aria-label="a dense table"
                        >
                            <TableBody>
                                {hours.map((h) => (
                                    <TableRow
                                        key={h.day}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                { border: 0 },
                                        }}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="right"
                                        >
                                            {h.day}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="left"
                                        >
                                            {h.hours}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        <p>
                            * Evenings by appointment and during major holidays,
                            hours are subject to change.
                        </p>
                        <br />
                        <a
                            href="https://www.facebook.com"
                            target="_blank"
                            rel="noreferrer"
                            hidden={true}
                        >
                            <img
                                className={classes.socialMediaImg}
                                src="/images/contact/facebook_logo_100.png"
                                alt="Facebook Logo"
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/strands95370/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className={classes.socialMediaImg}
                                src="/images/contact/instagram_logo.png"
                                alt="Instagram Logo"
                            />
                        </a>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={classes.shopImageDiv}>
                            <img
                                className={classes.shopImage}
                                src="/images/contact/407A1563.jpg"
                                alt="Shop"
                            />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

// export function Contact() {
//     return (
//         <h2>Contact...</h2>
//     );
// }
